<template>
    <div class="net-content">
        <div class="top-select">
            <span>状态：</span>
            <el-select v-model="status" placeholder="请选择" @change="getList">
                <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <el-table :data="studentSubmitList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px"
                  :tree-props="{children: 'subList', hasChildren: 'hasChildren'}"
                  :row-key="getRowKey" :expand-row-keys="expands"
                  height="1%">
<!--            <el-table-column prop="stu_name" label="学生名字" width="200"></el-table-column>
            <el-table-column prop="exam_name" label="考试名称" width="300">
                <template slot-scope="scope">
                    <span v-if="status == 1">{{scope.row.name}}</span>
                    <span v-else>{{scope.row.exam_name}}</span>
                </template>
            </el-table-column>-->
            <el-table-column prop="module" label="考试模块" width="150" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.exam_module_id == 42?'客户关系管理':'社会化客户服务'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" width="150" align="center">
                <template slot-scope="scope">
                    <span>{{options[status - 1].label}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="exam_time" label="考试时间" align="center" width="300">
                <template slot-scope="scope">
                    <span v-if="status == 1">{{scope.row.begin_time}}</span>
                    <span v-else>{{scope.row.exam_time}}</span>
                </template>
            </el-table-column>
            <el-table-column label="评分（最高分数为100分）" align="left">
                <template slot-scope="scope">
                    <span v-if="status == 1">该学生暂未提交无法评分</span>
                    <div class="link-input-box" v-else>
                        <el-input-number v-model="scope.row.score" :precision="0" :controls="false"></el-input-number>
                        <el-link type="warning" :underline="false" @click="submitScore(scope.row)" class="view-detail">
                            {{status == 2?'确定':'重新批改'}}
                        </el-link>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-box">
                        <el-link type="primary" :underline="false" @click="viewArticle(scope.row)"
                                 class="view-detail" v-if="status != 1">查看详情
                        </el-link>
                        <el-link type="primary" :underline="false" @click="viewGrading(scope.row)" class="view-detail">
                            评分标准
                        </el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="studentSubmitPages.currentPageNum"
                       :page-size="studentSubmitPages.eachPageNum"
                       :total="studentSubmitPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="studentSubmitCurrentChange">
        </el-pagination>
        <el-dialog title="评分标准"
                   :visible.sync="gradingDialog" width="830px" @close="handleCloseGradingDialog"
                   custom-class="green-dialog goods-score-dialog">
            <div class="upload-file-content">
                <el-table :data="gradingList" class="customTable goods-text-table" style="width: 100%; flex: 1;"
                          height="1%"
                          :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                          :cell-style="{fontSize: '14px',color: '#333333',height: '40px'}">
                    <el-table-column prop="index" label="序号" align="center"></el-table-column>
                    <el-table-column prop="content" label="考核内容" align="center"></el-table-column>
                    <el-table-column prop="require" label="考核要点" align="center"></el-table-column>
                    <el-table-column prop="standard" label="评分标准" align="center"></el-table-column>
                    <el-table-column prop="score" label="配分" align="center"></el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        customer_relations_correct_center,
        customer_relations_save_correct_score,
        customer_relations_get_standard
    } from '@/utils/apis'

    export default {
        name: "Index",
        data() {
            return {
                status: 2,
                exam_id: this.$route.query.examId,
                studentSubmitList: [],
                studentInfo: {},
                //评分标准列表
                gradingList: [],
                //评分标准弹窗
                gradingDialog: false,
                //分页
                studentSubmitPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                options: [
                    {
                        id: 1,
                        label: '未提交'
                    },
                    {
                        id: 2,
                        label: '未批改'
                    },
                    {
                        id: 3,
                        label: '已批改'
                    },
                ],
                expands: [],
            }
        },
        created() {
            this.getList();
        },
        methods: {
            getList() {
                let param = {
                    exam_id: this.exam_id,
                    type: this.status,
                    page: this.studentSubmitPages.currentPageNum,
                    paging: 1,
                    pageSize: this.studentSubmitPages.eachPageNum
                }
                customer_relations_correct_center(param).then(res => {
                    this.studentSubmitPages.total = res.data.total;
                    this.studentSubmitList = res.data.data
                }).catch(err => {
                    console.error(err)
                })
            },
            getRowKey(row) {
                return row.id;
            },
            viewArticle(row) {
                let role = localStorage.getItem('role');
                if (role == 3) {
                    this.$router.push({
                        path: '/teacher/cyberCustomerDetail',
                        query: {
                            examId: row.exam_id,
                            stuId: row.stu_id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/judges/cyberCustomerDetail',
                        query: {
                            examId: row.exam_id,
                            stuId: row.stu_id
                        }
                    })
                }
            },
            submitScore(row) {
                let param = {
                    exam_id: row.exam_id,
                    stu_id: row.stu_id,
                    score: row.score,
                }
                customer_relations_save_correct_score(param).then(res => {
                    this.$message.success(res.msg);
                    this.getList();
                }).catch(err => {
                    console.error(err)
                })
            },
            //下载文件
            downloadFile(row) {
                window.open(row.live_path, '_blank')
            },
            //查看评分标准
            viewGrading(row) {
                customer_relations_get_standard({op_id: row.exam_id}).then(res => {
                    this.gradingList = res.data.scoring_criteria;
                    this.gradingDialog = true;
                })
            },
            //关闭评分标准弹窗
            handleCloseGradingDialog() {
                this.gradingDialog = false;
            },
            //分页
            studentSubmitCurrentChange(val) {
                this.studentSubmitPages.currentPageNum = val;
                this.getList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .net-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;

        .top-select {
            margin-top: 20px;
        }

        .link-input-box {
            display: flex;

            .el-input-number {
                width: 88px;
            }

            .el-link {
                margin-left: 17px;
            }
        }

        .link-box {
            .el-link + .el-link {
                &:before {
                    display: none;
                }
            }
        }

        .goods-img-dialog {
            .upload-file-content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 445px;

                .big-img {
                    width: 444px;
                    height: 444px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .small-img {
                    flex: 1;
                    width: 1%;
                    margin-left: 94px;

                    img {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 15px;
                        border: 2px solid transparent;
                        box-sizing: border-box;

                        &:nth-child(2n-1) {
                            margin-right: 18px;
                        }

                        &:hover {
                            cursor: pointer;
                            border: 2px solid #2DC079;
                        }
                    }
                }
            }
        }
    }
</style>